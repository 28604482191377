
    import { defineComponent } from 'vue';
    import { IonList, IonCard, IonImg } from '@ionic/vue';
    import { PublicationApiClient, PublicationModel, EventCategoryModel, EventApiClient } from '../../generated';
    import { store } from '../../store/store';

    export default defineComponent({
        name: 'Events',
        components: {
            IonList,    
            IonCard,
            IonImg,
        },
        data() {
            return {
                publications: Array<PublicationModel>(),
                events: Array<EventCategoryModel>(),
            }
        },
        ionViewDidEnter() {
            this.loadData();
        },
        methods: {
            loadData: function() {
                const eventsClient = new EventApiClient();
                    eventsClient.getAll(store.state.currentLanguage).then(result => {                    
                    if (result != null) {
                        this.events = result;
                    }
                });
            }
        }
    })
